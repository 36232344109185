fury-alert-box {
  &.info {
    background: $theme-color-primary;
    color: #fff;
  }

  &.success {
    background: $theme-color-accent;
  }

  &.error,
  &.warn {
    background: #DD2815;
    color: #fff;
  }

  &.border {
    border: 1px solid #8e8e8e;
    font-weight: normal;
  }

  &.warn-outline {
    color: #dd2815;
    background: transparent;
    font-size: 0.9rem;
    border: 0;
    padding: 0;
  }

  &.info-outline {
    color: $theme-color-primary;
    font-size: 0.9rem;
    border: 0;
    padding: 0;

    mat-icon {
      font-size: 0.9rem;
      width: 0.9rem;
      height: 0.9rem;
      min-width: 0.9rem;
      min-height: 0.9rem;
      margin-right: 8px;
    }
  }

  &.warn-absolute {
    background: #DD2815;
    color: #fff;
    border-radius: 0;
    margin: 0;
    line-height: 20px;
  }

  &.without-border-radius {
    border-radius: 0;
  }

  &.without-bottom-spacing {
    margin-bottom: 0;
  }

  &.with-button {
    padding: 5px 15px;
  }
}
