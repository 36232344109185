.fury-page-layout {

}

.fury-page-layout-header {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d7d7d7;

  fury-breadcrumbs {
    .crumb, .link {
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .crumb.current, .crumb.current .link {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
