.ql-html {
  padding: 0 !important;

  &:before {
    font-family: 'Material Icons Outlined';
    content: '\eb7e';
    font-size: 24px;
  }
}

.ql-custom-tooltip {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;

  textarea {
    width: 100%;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      flex: 1 1 50%;
    }
  }
}
