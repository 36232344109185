@import '@angular/flex-layout/mq';

.fury-report-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 3%;
  right: 3%;
  bottom: 3%;
  left: 3%;
  animation: fade-out 0.25s;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  background: $sidebar-background;
  box-shadow: $sidebar-box-shadow;
  visibility: hidden;
  border-radius: $border-radius;

  &.open {
    animation: fade-in 0.25s;
    opacity: 1;
    visibility: visible;
  }
}
