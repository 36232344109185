@use 'sass:math';

html, body {
  min-height: 100%;
  height: 100%;
  font: var(--font-body-1);

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.fury-scrollblock {
  position: fixed;
  width: 100%;
}

.fury-collapsable-sidenav {
  overflow: visible !important;
  border-right: none !important;

  .mat-drawer-inner-container {
    overflow: visible !important;
  }
}

.fury-flex-form-field {
  .mat-form-field-infix {
    width: 0;
  }
}

.mat-menu-panel.fury-inbox-mail-label-list-menu {
  max-height: 350px;
  padding-top: math.div($spacing, 3);

  .fury-inbox-mail-label-list-menu-subheading {
    font: var(--font-caption);
    color: yellow; // TODO: $theme-secondary-text
    font-weight: 500;
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
    margin-bottom: math.div($spacing, 3);
  }

  .mat-checkbox {
    margin-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-create {
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-color, .fury-inbox-mail-label-list-name {

    .mat-form-field-infix {
      width: auto;
    }
  }
}

.fury-inbox-mail-label-list-color-select {
  .fury-inbox-mail-label-list-color-select-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: math.div($spacing, 2);
    vertical-align: middle;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

pre {
  margin: 0;
}

code {
  white-space: pre;
}

p {
  line-height: 1.5;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.quickpanel .mat-tab-body-content,
.quickpanel .mat-tab-body-wrapper,
.quickpanel mat-tab-body,
.quickpanel mat-tab-group {
  height: 100%;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.leaflet-container {
  font-family: inherit !important;
  font-size: inherit !important;
}

.quill-container {
  min-height: 300px;
  padding-bottom: 1.34375em;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.leaflet-custom-marker-pin {
  color: #333;
  position: absolute !important;
  border-radius: 50%;
  border: 8px solid currentColor;
  width: 24px;
  height: 24px;

  &::before {
    content: '';
    box-shadow: 0 20px 10px 0 #000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -15px;
    width: 26px;
    height: 4px;
    rotate: -43deg;
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid currentColor;
  }

  &.company-marker {
    border-width: 9px !important;;
    box-shadow: 1px 1px 3px -1px #000;
    width: 18px;
    height: 18px;

    &::before,
    &::after {
      display: none;
    }
  }
}

.leaflet-control.legend {
  border: 2px solid rgba(0,0,0,0.2);
  background: #fff;
  border-radius: 4px;
  padding: 10px 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .legend-toggle {
      margin: -10px;
      padding: 5px;
      width: 20px;
      height: 20px;
      line-height: 1;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .content {
    overflow: hidden;
    margin-top: 5px;

    &.collapsed {
      height: 0;
      margin-top: 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 6px;
      }
    }
  }
}

.quill-container {
  padding-bottom: 1.34375em;
}

.ql-editor {
  word-break: break-word;
}

.no-data-yet-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .mat-icon {
    margin-right: 8px;
  }

  &> div {
    font-weight: bold;
  }
}
