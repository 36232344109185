.mat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-badge-content.mat-primary,
.mat-chip.mat-primary {
  background-color: var(--primary-color);
  color: var(--primary-color-text)
}

.mat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-badge-content.mat-secondary,
.mat-chip.mat-secondary {
  background-color: var(--secondary-color);
  color: var(--secondary-color-text);
}

.mat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-badge-content.mat-accent,
.mat-chip.mat-accent {
  background-color: #DD2815;
  color: #ffffff;
}

.mat-button.mat-default,
.mat-raised-button.mat-default,
.mat-badge-content.mat-primary,
.mat-chip.mat-default {
  background-color: #cccccc;
  color: #333333;
}

.mat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-badge-content.mat-warn,
.mat-chip.mat-warn {
  background-color: #FFA500;
  color: #333333;
}

.mat-button.mat-black,
.mat-raised-button.mat-black,
.mat-badge-content.mat-black,
.mat-chip-mat-black {
  background-color: #333;
  color: #fff;
}

.mat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-button.mat-secondary[disabled],
.mat-raised-button.mat-secondary[disabled],
.mat-button.mat-accent[disabled],
.mat-raized-button.mat-accent[disabled],
.mat-button.mat-default[disabled],
.mat-raised-button.mat-default[disabled],
.mat-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-icon-button[disabled] {
  opacity: 0.6;
  box-shadow: none;
}

.mat-checkbox-indeterminate.mat-dark-grey .mat-checkbox-background,
.mat-checkbox-checked.mat-dark-grey .mat-checkbox-background {
  background: #555555;
}
